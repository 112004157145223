import React from "react";
import SEO from "../components/utilities/SEO";
import { Link } from "gatsby";

const NotFoundPage = () => (
  <>
    <SEO title="Mitte Leitud!" />
    <div className="m-3 text-center">
      <h1 style={{ color: "darkred" }}>404 Error!</h1>
      <span>
        Oih! Olete sattunud vigasele lehele! Vajutage{" "}
        <Link to="/" className="font-weight-bold">
          siia
        </Link>
        , et minna ARMACOM OÜ avalehele.
      </span>
    </div>
  </>
);

export default NotFoundPage;
